import React from 'react'
import renderBloks from '@renderBloks'
import Layout from '../layout'
import SbEditable from 'storyblok-react'
import makeStyles from '@material-ui/styles/makeStyles'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import ACC_THEME from '@themes'
import H3 from '@system/h3'
import H5 from '@system/h5'
import Text from '@system/text'
import TextSm from '@system/text-sm'
import Phases from '@system/phases'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import Img from '@system/img'
import AutodeskProductLogo from '@system/autodesk-product-logo'
import get from 'lodash/get'
import HorizontalRule from './horizontal-rule'
import font from '@helpers/font'
import { imgMaxWidths } from '@variables/global-variables'

const getProductIcon = (label) => {
  switch (label) {
    case 'Assemble':
      return {
        label: 'AssembleIcon',
        imgPath: '//a.storyblok.com/f/64835/x/2b5d477d2e/assemble-icon.svg',
      }
    case 'BIM360':
      return {
        label: 'BIM360Icon',
        imgPath: '//a.storyblok.com/f/64835/x/73c8fdc944/bim360-icon.svg',
      }
    case 'BuildingConnected':
      return {
        label: 'BuildingConnectedIcon',
        imgPath:
          '//a.storyblok.com/f/64835/x/93e65bc3c0/buildingconnected-icon.svg',
      }
    case 'PlanGrid':
      return {
        label: 'PlanGridIcon',
        imgPath: '//a.storyblok.com/f/64835/x/3e43912148/plangrid-icon.svg',
      }
    case 'Cost':
      return {
        label: 'Cost',
        imgPath: 'https://a.storyblok.com/f/64835/x/900864051b/ico_budget.svg',
      }
    case 'Quality':
      return {
        label: 'Quality',
        imgPath:
          'https://a.storyblok.com/f/64835/x/a8ff72cee0/ico_quality-management.svg',
      }
    case 'Schedule':
      return {
        label: 'Schedule',
        imgPath:
          'https://a.storyblok.com/f/64835/x/7871f812ac/ico_schedule-management-1.svg',
      }
    case 'Safety':
      return {
        label: 'Safety',
        imgPath:
          'https://a.storyblok.com/f/64835/x/59c6d363b0/ico_safety-inspections.svg',
      }
    case 'Winning Business':
      return {
        label: 'Winning Business',
        imgPath: 'https://a.storyblok.com/f/64835/x/766877eb70/frame.svg',
      }
    default:
      return ''
  }
}

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((props) => ({
  format: {
    color: ACC_THEME.palette.background.slate,
  },
  topContainer: {
    position: 'relative',
    marginTop: (props) => (props.isLegacyTemplate ? '-100px' : '-250px'),
    width: '100%',
  },
  title: {
    color: ACC_THEME.palette.secondary.main,
    textAlign: (props) => (props.isLegacyTemplate ? 'center' : 'left'),
    display: 'block',
  },
  subtitle: {
    color: ACC_THEME.palette.text.tertiary,
    textAlign: (props) => (props.isLegacyTemplate ? 'center' : 'left'),

    [ACC_THEME.breakpoints.down('sm')]: {
      paddingBottom: '30px',
    },
    [ACC_THEME.breakpoints.up('sm')]: {
      paddingBottom: '45px',
    },
    display: 'block',
  },
  titleSubtitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: ACC_THEME.palette.common.white,
    margin: '0 auto',

    [ACC_THEME.breakpoints.down('sm')]: {
      padding: (props) => props.isLegacyTemplate && '50px 20px 20px',
    },
    [ACC_THEME.breakpoints.up('sm')]: {
      padding: (props) => props.isLegacyTemplate && '50px 64px 40px',
    },
  },
  headerImage: {
    width: '100%',
    height: '600px',
    objectPosition: 'top',
    maxHeight: '600px',
    '& img': {
      objectFit: 'cover !important',
    },
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [ACC_THEME.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  logo: {
    margin: '16px 0px',
    '& img': {
      width: '225px !important',
      objectFit: 'contain !important',
    },
  },
  leftColumn: {
    width: (props) => (props.isLegacyTemplate ? '100%' : '60%'),
    [ACC_THEME.breakpoints.up('sm')]: {
      padding: '80px 30px 40px 0px',
    },
    [ACC_THEME.breakpoints.down('sm')]: {
      width: '100% !important',
      padding: '10px 20px 20px 10px',
    },
  },
  rightColumn: {
    width: '35%',
    padding: '50px 0px 40px 50px',
    color: ACC_THEME.palette.background.slate,
    [ACC_THEME.breakpoints.down('sm')]: {
      width: '70%',
      paddingLeft: '10px',
      paddingBottom: '10px',
    },
    [ACC_THEME.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  outerContainer: {
    padding: '10px 0px',
    [ACC_THEME.breakpoints.down('xs')]: {
      marginBottom: '30px',
    },
  },
  customerSnapshotValue: {
    textTransform: 'uppercase',
    fontWeight: `${ACC_THEME.typography.fontWeightBold} !important`,
    [ACC_THEME.breakpoints.between('md', 'lg')]: {
      ...makeResponsiveFontSizes(16, 11),
    },
  },
  sidebarTitle: {
    fontWeight: `${ACC_THEME.typography.fontWeightBold} !important`,
    paddingBottom: '10px',
  },
  customerDetails: {
    display: 'flex',
    marginBottom: '8px',
  },
  customerSnapshots: {
    minWidth: '130px',
    [ACC_THEME.breakpoints.between('sm', 'md')]: {
      ...makeResponsiveFontSizes(16, 11),
    },
  },
  container: {
    marginBottom: '30px',
    [ACC_THEME.breakpoints.down('xs')]: {
      marginBottom: '50px',
    },
  },
  products: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '10px',
    '& img': {
      display: 'block',
      height: '40px',
      marginRight: '5px',
      objectPosition: 'center',
      width: '40px !important',
    },
  },
  valueDriverContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    margin: '20px 0px',
    justifyContent: 'space-between',
    [ACC_THEME.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
  valueDriver: {
    marginBottom: '20px',
    display: 'flex',
    marginRight: '10px',
    alignItems: 'center',
    '& img': {
      display: 'block',
      height: '40px',
      marginRight: '10px',
      objectPosition: 'center',
      width: '40px !important',
      [ACC_THEME.breakpoints.between('sm', 'md')]: {
        height: '33px',
        marginRight: '5px',
        width: '33px !important',
      },
    },
  },
  valueDriverText: {
    textAlign: 'center',
  },
  productsUsedOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    top: '50%',
    zIndex: 1,
    margin: '0 auto',
    width: '100%',
    [ACC_THEME.breakpoints.down('sm')]: {
      borderTop: `1px solid ${ACC_THEME.palette.text.tertiary}`,
    },
    [ACC_THEME.breakpoints.up('sm')]: {
      borderTop: `0.5px solid ${ACC_THEME.palette.text.tertiary}`,
    },
  },
  productsUsedInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 10,
    backgroundColor: ACC_THEME.palette.common.white,
    marginTop: '-20px',
    padding: '0 20px',
  },
  img: {
    display: 'block',
    maxHeight: '30px',
    maxWidth: '30px',
  },
}))

const CustomerDetail = (props) => {
  const classes = useStyles(props.blok)

  const horizontalRuleProps = {
    color: { color: ACC_THEME.sidebar.backgroundColor },
    widthProportion: 100,
    lineHeight: 4,
    margin: '30px 0px',
  }
  const {
    companyLogo,
    firmSize,
    firmType,
    revenue,
    focusArea,
    city,
    valueDriver,
    country,
    fullWidthModule,
    image,
    productsUsed,
    metaData,
    title,
    body,
    subtitle,
    assetDownloadBanner,
    wistiaVideoModule,
    segment,
    isLegacyTemplate,
    phases,
    capabilities,
    hideCapabilities,
    hideProducts,
  } = props.blok

  const headerImage = get(image, '[0].image')
  const location = city ? `${city}, ${country}` : `${country}`

  const isEmptyArray = (array) => {
    return !array || array.length === 0
  }

  const industry =
    (!isEmptyArray(focusArea) && focusArea.join(', ')) ||
    (!isEmptyArray(segment) && segment.join(', '))

  const CustomerSnapshotDetails = (props) => (
    <Box className={classes.customerDetails}>
      <Box className={classes.customerDetails}>
        <Text className={classes.customerSnapshots}>{props.children}</Text>
        <Text className={classes.customerSnapshotValue}>{props.value}</Text>
      </Box>
    </Box>
  )

  const SidebarModules = () => (
    <Box className={classes.rightColumn}>
      {!!companyLogo && (
        <Box className={classes.logo}>
          <Img src={companyLogo.filename} alt={companyLogo.name} />
        </Box>
      )}
      <HorizontalRule blok={horizontalRuleProps} />
      <Box className={classes.outerContainer}>
        <H5 className={classes.sidebarTitle}>Customer Snapshot</H5>
        {!!firmSize && (
          <CustomerSnapshotDetails value={firmSize}>
            FIRM SIZE :
          </CustomerSnapshotDetails>
        )}
        {!isEmptyArray(firmType) && (
          <CustomerSnapshotDetails value={firmType.join(', ')}>
            FIRM TYPE :
          </CustomerSnapshotDetails>
        )}
        {!!revenue && (
          <CustomerSnapshotDetails value={revenue}>
            REVENUE :
          </CustomerSnapshotDetails>
        )}
        {(!isEmptyArray(focusArea) || !isEmptyArray(segment)) && (
          <CustomerSnapshotDetails value={industry}>
            FOCUS AREA :
          </CustomerSnapshotDetails>
        )}
        {(!!city || !!country) && (
          <CustomerSnapshotDetails value={location}>
            HQ :
          </CustomerSnapshotDetails>
        )}
      </Box>
      <HorizontalRule blok={horizontalRuleProps} />

      {!!phases && (
        <Box className={classes.container}>
          <H5>PHASE: </H5>
          <Phases phase={phases} />
        </Box>
      )}
      {!isEmptyArray(productsUsed) && !hideProducts && (
        <Box className={classes.container}>
          <H5>AUTODESK PRODUCTS: </H5>
          <Box>
            {productsUsed.map((imageObj, index) => {
              return (
                <Box p={1} key={index} className={classes.products}>
                  <AutodeskProductLogo
                    textAlign="left"
                    name={imageObj}
                    shouldAppendAutodesk={imageObj === 'BIM360'}
                    fontSize={4}
                    hasPrecedingLogo={true}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      )}
      {!isEmptyArray(capabilities) && !hideCapabilities && (
        <Box className={classes.container}>
          <H5>Capabilities:</H5>
          <Box className={classes.valueDriverContainer}>
            {capabilities.map((driver, i) => (
              <Box key={i} className={classes.valueDriver}>
                <Text className={classes.valueDriverText}>• {driver}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {!isEmptyArray(valueDriver) && (
        <Box className={classes.container}>
          <H5>OUTCOMES:</H5>
          <Box className={classes.valueDriverContainer}>
            {valueDriver.map((driver, i) => (
              <Box key={i} className={classes.valueDriver}>
                <Img
                  src={getProductIcon(driver).imgPath}
                  className={classes.valueDriverIcons}
                />
                <Text className={classes.valueDriverText}>{driver}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={props.blok}>
          {isLegacyTemplate ? (
            <Box minHeight="200px">{renderBloks(image)}</Box>
          ) : (
            !!headerImage && (
              <Img
                className={classes.headerImage}
                maxWidth={imgMaxWidths.headerImage}
                src={headerImage}
              />
            )
          )}
          <Box className={classes.topContainer}>
            <PageContainer>
              <ContentContainer>
                {isLegacyTemplate ? (
                  <Box className={classes.titleSubtitleContainer}>
                    <H3 component="h1" className={classes.title}>
                      {title}
                    </H3>
                    <H5 className={classes.subtitle}>{subtitle}</H5>
                    <Box className={classes.productsUsedOuterContainer}>
                      <Box className={classes.productsUsedInnerContainer}>
                        {productsUsed &&
                          productsUsed.map((imageObj, index) => {
                            return (
                              <Box p={1} key={index}>
                                <img
                                  src={getProductIcon(imageObj).imgPath}
                                  className={classes.img}
                                />
                              </Box>
                            )
                          })}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.flexBox}>
                    <Box className={classes.leftColumn}>
                      <TextSm className={classes.format}>CUSTOMER STORY</TextSm>
                      <Box className={classes.titleSubtitleContainer}>
                        <H3 component="h1" className={classes.title}>
                          {title}
                        </H3>
                        <Text className={classes.subtitle}>{subtitle}</Text>
                      </Box>
                      {renderBloks(body, {
                        isCustomersModule: true,
                      })}
                      {!!assetDownloadBanner &&
                        assetDownloadBanner.length > 0 &&
                        renderBloks(assetDownloadBanner)}
                      {!!wistiaVideoModule &&
                        wistiaVideoModule.length > 0 &&
                        renderBloks(wistiaVideoModule)}
                    </Box>
                    <SidebarModules />
                  </Box>
                )}
                {!isLegacyTemplate &&
                  !!fullWidthModule &&
                  fullWidthModule.length > 0 &&
                  renderBloks(fullWidthModule)}
              </ContentContainer>
            </PageContainer>
            {isLegacyTemplate &&
              renderBloks(body, {
                isLegacyTemplate: true,
              })}
          </Box>
        </SbEditable>
      </ThemeProvider>
    </Layout>
  )
}

export default CustomerDetail
